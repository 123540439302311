<template>
  <div>
    <el-row :gutter="10">
      <p>Тип лота</p>
      <el-select
          v-model="type"
          class="m-2"
          placeholder="Тип лота"
          size="large"
          @change="console.log(search)"
        >
          <el-option
            v-for="item in press"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
      </el-select>
    </el-row>
    Тип отхода производства:
    <el-radio-group v-model="search.waste_input" class="ml-4">
      <el-radio 
      label="Справочник"></el-radio>
      <el-radio
      label="Ручной ввод"></el-radio>
    </el-radio-group>
    <p>Укажите отход</p>
    <el-row :gutter="10" v-if="search.waste_input == 'Ручной ввод'">
      <el-col :span="10">
        <el-input
          v-model="search.code_user"
          placeholder="Код"
          class="input-waste-code"
        />
      </el-col>
      <el-col :span="10">
        <el-input
          v-model="search.name_user"
          placeholder="Название"
          class="input-waste-code"
        />
      </el-col>
    </el-row>
    <el-row :gutter="10" v-if="search.waste_input == 'Справочник'">
      <el-col :span="12">
        <el-select
          clearable
          @change="
            register = search.name = wasteNames.find(
              (obj) => obj.id === search.code.id
            )
          "
          class="searchPole"
          v-model="search.code"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Код отхода"
          :remote-method="searchCode"
        >
          <el-option
            v-for="item in ss01CodeList"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
      <el-col :span="12">
        <el-select
          clearable
          @change="
            register = search.code = ss01CodeList.find(
              (obj) => obj.id === search.name.id
            )
          "
          class="searchPole"
          v-model="search.name"
          filterable
          remote
          value-key="id"
          reserve-keyword
          placeholder="Наименование"
          :remote-method="searchWasteName"
        >
          <el-option
            v-for="item in wasteNames"
            :key="item.id"
            :label="item.name"
            :value="item"
          >
          </el-option>
        </el-select>
      </el-col>
    </el-row>
    <p>Местоположение отхода</p>
    <el-row :gutter="10">
      <el-col :span="12">
        <el-select
          v-model="search.object"
          class="m-2"
          placeholder="Местоположение отхода"
        >
          <el-option
            v-for="item in organizationHierarchyByType"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <p>Предлагаемая цена</p>
      <el-col :span="10">
        <el-input
          v-model="search.min_prise"
          placeholder="От BYN"
          class="input-waste-code"
        />
      </el-col>
      <el-col :span="10">
        <el-input
          v-model="search.max_prise"
          placeholder="До BYN"
          class="input-waste-code"
        />
      </el-col>
      <el-col :span="4">
        <el-radio-group v-model="search.nds">
          <el-radio :label="true">с НДС</el-radio>
          <el-radio :label="false">без НДС</el-radio>
        </el-radio-group>
      </el-col>
    </el-row>

    <el-row :gutter="10">
      <p>Масса отхода</p>
      <el-col :span="12">
        <el-input
          v-model="search.mass_min"
          placeholder="масса отхода"
          class="input-waste-code"
        />
      </el-col>
      <el-col :span="12">
        <el-select
          v-model="search.mass_max"
          class="m-2"
          placeholder="единица измерения"
          size="large"
        >
          <el-option
            v-for="item in pss93UnitsInfoList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          />
        </el-select>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <p>Стоимость</p>
      <el-col :span="12">
        От
        {{
          Math.round((search.mass_min == null ? 0 : parseFloat(search.mass_min.replace(",", "."))) *
          (search.min_prise == null ? 0 : parseFloat(search.min_prise.replace(",", ".")))*1000)/1000
        }}
        BYN - До
        {{
          Math.round((search.mass_min == null ? 0 : parseFloat(search.mass_min.replace(",", "."))) *
          (search.max_prise == null ? 0 : parseFloat(search.max_prise.replace(",", ".")))*1000)/1000
        }}
        BYN
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <p>Срок размещения лота</p>
      <el-col :span="6">
        <el-date-picker
          v-model="search.dateFrom"
          style="width: 100%"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Дата"
          class="dateIn"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="6">
        <el-date-picker
          v-model="search.dateTo"
          style="width: 100%"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="Дата"
          class="dateIn"
        >
        </el-date-picker>
      </el-col>
    </el-row>
    <el-row :gutter="10">
      <p>Состав отхода</p>
      <el-input
        v-model="search.texts"
        placeholder="Укажите состав отхода"
        class="input-waste-compound"
      />
    </el-row>

    <el-row :gutter="10">
      <p>Транспортировка отхода</p>
      <el-select
        v-model="search.delivery"
        class="m-2"
        placeholder="Транспортировка отхода"
        size="large"
      >
        <el-option
          v-for="item in delivery"
          :key="item.id"
          :label="item.label"
          :value="item.id"
        />
      </el-select>
    </el-row>
    <el-row :gutter="10">
    
    </el-row>
    <div class="public-offer">
      <el-button type="primary" @click="add()">Опубликовать лот</el-button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import { mapGetters } from "vuex";

export default {
  name: "addWasteOffer",
  data() {
    return {
    press:[
        {
          id: 1,
          name: "Продажа",
        },
        {
          id: 2,
          name: "Покупка",
        }
      ],
      delivery: [
        {
          id: 1,
          label: "транспортом производителя",
        },
        {
          id: 2,
          label: "транспортом переработчика",
        },
        {
          id: 3,
          label: "транспортом иной организации",
        },
      ],
      wasteNames: [],
      ss01CodeList: [],
      pss93UnitsInfoList: [],
      search: {
        waste_input:"Справочник",
        code_user:null,
        name_user:null,
        code: null,
        name: null,
        nds: false,
        min_prise: null,
        max_prise: null,
        mass_min: null,
        mass_max: null,
        texts:null,
      },
      type: null,
      once: false,
    };
  },
  methods: {
    getOrganizationHierarchyByType() {
      this.$store.dispatch("getOrganizationHierarchyByType", {
        type: 3,
      });
    },
    async searchCode(query) {
      await this.$store.dispatch("getCodeList", query);
      this.ss01CodeList = this.ss01CodeList0;
      this.wasteNames = [];
      this.ss01CodeList0.forEach((ss01Code) => {
        this.wasteNames.push({ id: ss01Code.id, name: ss01Code.name2 });
      });
    },

    async searchWasteName(query) {
      await this.$store.dispatch("getWasteNames", query);
      this.wasteNames = this.wasteNames0;
      this.ss01CodeList = [];
      this.ss01CodeList.forEach((wasteName) => {
        this.wasteNames.push({ id: wasteName.id, name: wasteName.name2 });
      });
    },

    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },

    add() {
      this.search.min_prise=this.search.min_prise==null || this.search.min_prise=="" ? "0.0" :this.search.min_prise;
      this.search.max_prise=this.search.max_prise==null || this.search.max_prise=="" ? "0.0" :this.search.max_prise;
      let lots = {
        once:this.once,
        lotsId: 0,
        waste_code: this.search.waste_input == "Ручной ввод" ? null : parseInt(this.search.code.name),
        waste_name: this.search.waste_input == "Ручной ввод" ? null : this.search.name.name,
        adress: this.search.object,
        price_min:  parseFloat(this.search.min_prise.replace(",", ".")),
        price_max:  parseFloat(this.search.max_prise.replace(",", ".")),
        nds: this.search.nds,
        date_from: this.search.dateFrom,
        date_to: this.search.dateTo,
        text: this.search.texts,
        disposal: this.search.delivery,
        mass_min:  parseFloat(this.search.mass_min.replace(",", ".")),
        mass_max: this.search.mass_max,
        done: null,
        delet: null,
        date_delet: null,
        reason_delet: null,
        user_id: 0,
        waste_code_user:this.search.waste_input == "Ручной ввод" ? parseInt(this.search.code_user) : null,
        waste_name_user:this.search.waste_input == "Ручной ввод" ? this.search.name_user : null,
      };
      let data = { lots: lots, type: this.type };
      this.$store.dispatch("addLots", data);
      this.$store.dispatch("setShowModal", null);
      this.$emit('reloadParent');
    },
  },
  computed: {
    ...mapGetters({
      organizationHierarchyByType: "GETORGANIZATIHIERARCHYBYTYPE",
      ss01CodeList0: "GETSSS01CODELIST",
      wasteNames0: "GETWASTENAMES",
      foundedWaste: "GETSS01DICTIONARY",
    }),
  },
  mounted() {
    this.getOrganizationHierarchyByType();
    this.getSubClassifierById(93).then((response) => {
      this.pss93UnitsInfoList = response.data.content;
      this.search.mass_max=this.pss93UnitsInfoList[0].id;
    });
    console.log(this);
  },
};
</script>

<style scoped>
.public-offer {
  margin-top: 20px;
}
</style>
